import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

export const HeroButtWhiteNoLink = styled.div`
    min-width: max-content;
    color: white;
    background: transparent;
    font-size: 30pt;
    text-decoration: none;
    border-style: solid;
    border-width: 2px;
    border-color: white;
    padding: 10px 32px 8px 32px;
    transition: all 0.5s ease 0s;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 3px;
    cursor: pointer;
    
    :hover {
        background: white;
        color: black;
        border-color: white;
    }

    @media screen and (max-width: 813px) {
        font-size: 4vw;
        padding: 3% 5%;
        border-width: 1.5px;
    }
`

export const HeroButtWhite = styled.div`
    padding: 0px;
    min-width: max-content;

    a {
        color: white;
        background: transparent;
        font-size: 30pt;
        text-decoration: none;
        border-style: solid;
        border-width: 2px;
        border-color: white;
        padding: 10px 32px 8px 32px;
        transition: all 0.5s ease 0s;
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    a:hover {
        background: white;
        color: black;
        border-color: white;
    }

    @media screen and (max-width: 813px) {
        a {
            font-size: 4vw;
            padding: 3% 5%;
            border-width: 1.5px;
        }
    }
`

export const HeroButtDark = styled.div`
    padding: 0px;
    min-width: max-content;
    a {
        color: #444444;
        background: transparent;
        font-size: 30pt;
        text-decoration: none;
        border-style: solid;
        border-width: 2px;
        border-color: #444444;
        padding: 10px 32px 8px 32px;
        transition: all 0.5s ease 0s;
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    a:hover {
        background: #444444;
        color: white;
        border-color: #444444;
    }

    @media screen and (max-width: 813px) {
        a {
            font-size: 4vw;
            padding: 3% 5%;
            border-width: 1.5px;
        }
    }
`

export function HeroButtonWhite( props ) {
    return (
        <HeroButtWhite>
            <Link to={props.to}>{props.text}</Link>
        </HeroButtWhite>
    )
}

export function HeroButtonDark( props ) {
    return (
        <HeroButtDark>
            <Link to={props.to}>{props.text}</Link>
        </HeroButtDark>
    )
}
