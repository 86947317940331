import React from "react"
import sidebarStyles from "./sidebar.module.css"

export function Sidebar( props ) {
    return (
        <div className={ sidebarStyles.sidebar }>
            <input type="checkbox" id={ sidebarStyles.navCheck } />
            <div class="nav-header">
                <div class="nav-title">
                    {props.title}
                </div>
            </div>
            <div class={ sidebarStyles.navbtn }>
                <label for={ sidebarStyles.navCheck }>
                    <span></span>
                    <span></span>
                    <span></span>
                </label>
            </div>
            <div class={ sidebarStyles.navlinks }>
                {props.links}
                <div class={ sidebarStyles.langlink }>
                    <hr style={{ margin: 0 }}/>
                    {props.langLinkText}
                </div>
            </div>
        </div>
    )
}

export function Content({ children }) {
    return (
        <div className={ sidebarStyles.content }>
            {children}
        </div>
    )
}
