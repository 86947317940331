import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import CookieConsent from "react-cookie-consent"

import SEO from "./seo"
import { Sidebar, Content } from "./sidebar"
import { HeroButtWhiteNoLink } from "./buttons"

import Logo from "../images/Logo.png"

const Footer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    padding: 32px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 12pt;

    @media screen and (max-width: 813px) {
        font-size: 0.8rem;
        padding: 5%;
    }
`

const CopyText = styled.div`
    flex: 0 1 33%;
    text-align: left;
    max-width: min-content;

    li {
        display: inline;
        padding-right: 30px;
    }

    @media screen and (max-width: 1113px) {
        li {
            display: list-item;
            padding-right: 0;
        }
    }
`

const FooterLinks = styled.div`
    flex: 1 1 33%;
    text-align: right;

    li {
        display: inline;
        padding-left: 30px;
    }

    @media screen and (max-width: 1113px) {
        li {
            display: list-item;
            padding-left: 0;
        }
    }
`

export function Layout( props ) {
    return (
        <div>
            <SEO 
                title={props.title}
                lang={props.lang}
            />
            <CookieConsent
                disableButtonStyles={true}
                location="bottom"
                ButtonComponent={HeroButtWhiteNoLink}
                buttonText={(props.lang === "de") ? "Akzeptieren" : "Accept"}
                buttonStyle={{ margin: "15px" }}
                expires={60}
                style={{ alignItems: "center", boxShadow: "0px 0px 30px 20px rgba(0, 0, 0, .8)" }}
                cookieName="gatsby-gdpr-google-analytics" overlay>
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: "0 1 auto", fontSize: "35pt", paddingRight: "20px" }}>🍪</div>
                        <div style={{ flex: "auto" }}>
                            <p style={{fontSize: "25pt", lineHeight: "1em", color: "#FFF"}}>
                                {(props.lang === "de")
                                    ? <span>Diese Seite nutzt Cookies, um Ihre Benutzererfahrung zu verbessern.</span>
                                    : <span>This site uses cookies, to improve your user experience.</span>
                                }
                            </p>
                            {(props.lang === "de")
                                ? <p style={{ fontSize: "11pt", lineHeight: "1.5em", color: "#DDD" }}>
                                    Sehen Sie sich unsere <Link to="/dataprotection/" style={{ color: "#BBBBBB" }}>Datenschutzerklärung</Link> an für mehr Informationen.
                                    Alle Daten sind anonymisiert und dienen ausschließlich der Sicherstellung der Funktionalität dieser Website.</p>
                                : <p style={{ fontSize: "11pt", lineHeight: "1.5em", color: "#DDD" }}>
                                    See our <Link to="/dataprotection/" style={{ color: "#BBBBBB" }}>privacy policy</Link> for more information.
                                    All data is anonymized and used solely to ensure the functionality of this website.</p>
                            }
                        </div>
                </div>
            </CookieConsent>
            <Sidebar
                title={
                    <Link to={(props.lang === "de") ? "/" : "/en/"}>
                    <img
                        src={Logo}
                        width="100%"
                        alt="" />
                    </Link>
                }
                links={(props.lang === "de")
                        ? <div>
                            <Link style={(props.title === "Home") ? {color: "#007070" } : {}} to="/">Home</Link>
                            <Link style={(props.title === "Leistungen") ? {color: "#007070" } : {}} to="/products/">Leistungen</Link>
                            <Link style={(props.title === "Referenzen") ? {color: "#007070" } : {}} to="/references/">Referenzen</Link>
                            <Link style={(props.title === "Karriere") ? {color: "#007070" } : {}} to="/career/">Karriere</Link>
                            <Link style={(props.title === "Über Uns") ? {color: "#007070" } : {}} to="/about/">Über uns</Link>
                        </div>
                        : <div>
                            <Link style={(props.title === "Home") ? {color: "#007070" } : {}} to="/en/">Home</Link>
                            <Link style={(props.title === "Products") ? {color: "#007070" } : {}} to="/en/products/">Products</Link>
                            <Link style={(props.title === "References") ? {color: "#007070" } : {}} to="/en/references/">References</Link>
                            <Link style={(props.title === "Career") ? {color: "#007070" } : {}} to="/en/career/">Career</Link>
                            <Link style={(props.title === "About us") ? {color: "#007070" } : {}} to="/en/about/">About us</Link>
                        </div>
                }
                langLinkText={(props.lang === "de")
                    ? <Link to={`/en/${props.actSite}`}><span style={{ color: "#007070" }}>DE</span><span> | EN</span></Link>
                    : <Link to={`/${props.actSite}`}><span>DE | </span><span style={{ color: "#007070" }}>EN</span></Link>
                }
            />
            <Content>
                {props.children}
                <hr style={{ width: "100%", margin: "0", padding: "0" }}></hr>
                <Footer>
                    <CopyText>
                        <ul style={{ listStyleType: "none", padding: "0", margin: "0" }}>
                            <li><nobr>© 2020 Metallbau Kessler GmbH & Co.KG</nobr></li>
                            <li><nobr>Pictures © Schüco International KG</nobr></li>
                        </ul>
                    </CopyText>
                    <FooterLinks>
                        <ul style={{ listStyleType: "none", padding: "0", margin: "0" }}>
                        {(props.lang === "de")
                            ? <li><Link to="/dataprotection/" style={{ color: "#777777" }}>Datenschutzerklärung</Link></li>
                            : <li><Link to="/en/dataprotection/" style={{ color: "#777777" }}>Privacy Policy</Link></li>}
                        {(props.lang === "de")
                            ? <li><Link to="/impressum/" style={{ color: "#777777" }}>Impressum</Link></li>
                            : <li><Link to="/en/impressum/" style={{ color: "#777777" }}>Imprint</Link></li>}
                        </ul>
                    </FooterLinks>
                </Footer>
            </Content>
        </div>
    )
}
